import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import { MainLayout } from '../layouts/main'
import { Link } from '../components/link'
import { Catalog } from '../components/catalog'

export default function CategoryTemplate(props) {
  const { category } = props.data.postgres

  return (
    <MainLayout>
      <Catalog>
        <Catalog.TitleBar>{category.name}</Catalog.TitleBar>

        {category.childCategories.nodes.map((childCategory) => {
          return (
            <div key={childCategory.id}>
              <Catalog.SubTitle>{childCategory.name}</Catalog.SubTitle>

              <Catalog.List>
                {childCategory.products.map((product) => {
                  const path = `/catalog/${childCategory.urlSlug}/${product.urlSlug}`
                  const RenderItem = (p) => <Link to={path} {...p} />

                  return (
                    <Catalog.List.Item
                      key={product.id}
                      renderItem={RenderItem}
                      product={product}
                    />
                  )
                })}
              </Catalog.List>
              <Catalog.BackToTop />
            </div>
          )
        })}

        <Catalog.List>
          {category.products.map((product) => {
            const path = `/catalog/${category.urlSlug}/${product.urlSlug}`
            const RenderItem = (p) => <Link to={path} {...p} />

            return (
              <Catalog.List.Item
                key={product.id}
                renderItem={RenderItem}
                product={product}
              />
            )
          })}
        </Catalog.List>
        {category.products.length !== 0 && <Catalog.BackToTop />}
      </Catalog>
    </MainLayout>
  )
}
CategoryTemplate.propTypes = {
  data: PropTypes.shape({
    postgres: PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        urlSlug: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(
          PropTypes.shape({ id: PropTypes.string.isRequired }),
        ).isRequired,
        childCategories: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              urlSlug: PropTypes.string.isRequired,
              products: PropTypes.arrayOf(
                PropTypes.shape({ id: PropTypes.string.isRequired }),
              ).isRequired,
            }),
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query ($categoryId: PostGraphile_UUID!) {
    postgres {
      category(id: $categoryId) {
        id
        name
        urlSlug
        products {
          id
          ...ProductListItem
        }
        childCategories(orderBy: ORDER_ASC) {
          nodes {
            id
            name
            urlSlug
            products {
              id
              ...ProductListItem
            }
          }
        }
      }
    }
  }
`
